<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <div class="card-label">
            <div class="font-weight-bolder">
              Vos fichiers ont bien été envoyés !
            </div>
          </div>
        </div>
      </div>
      <!--begin::Body-->
      <perfect-scrollbar
        class="scroll card-body text-dark-50 font-size-lg"
        style="max-height: 500px; position: relative;"
      >
        <p class="mb-6">
          Le lien de téléchargement a été envoyé par e-mail et sera disponible
          pendant 14 jours.
        </p>
        <div class="text-center">
          <i class="fas fa-check-circle text-success fa-10x"></i>
        </div>
        <p class="my-6 text-center">
          Merci d'avoir utilisé Central Core.
        </p>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadSuccess"
};
</script>
